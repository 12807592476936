import React from 'react';
import { arrayOf, bool, string, shape, func, oneOfType, node, number } from 'prop-types';
import classnames from 'classnames';
import Media from '../media';
import { Remaining } from '../../prop-types';
import IconFactory from '../icons/factory-builder';
import { setTooltipProps } from '../tooltip/information-tooltip-props';
import adaptSubtitlesToRemainingLogic from '../../utils/adaptSubtitlesToRemainingLogic';

const namespace = 'ui-pdp-pick-up';

const PickUp = ({
  action,
  className,
  icon,
  subtitles,
  title,
  isFetching,
  remaining,
  onShippingPromiseUpdate,
  tooltip,
  showChevron,
  num_font_size,
  key,
}) => {
  let adaptedSubtitles = null;

  if (subtitles) {
    adaptedSubtitles = subtitles.slice();
  }

  if (remaining && subtitles) {
    adaptSubtitlesToRemainingLogic(remaining, onShippingPromiseUpdate, subtitles, adaptedSubtitles);
  }

  return (
    <Media
      figure={icon && IconFactory(icon, `${namespace}-icon`)}
      className={classnames(namespace, className, {
        [`${namespace}--remaining`]: remaining,
      })}
      subtitles={adaptedSubtitles}
      title={title}
      action={action}
      num_font_size={num_font_size}
      key={key}
      isFetching={isFetching}
      showChevron={showChevron}
      tooltip={tooltip}
    />
  );
};

PickUp.propTypes = {
  action: oneOfType([
    shape({
      label: shape({
        text: string.isRequired,
        color: string,
      }).isRequired,
      target: string.isRequired,
      onClick: func,
    }),
    node,
    func,
  ]),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  isFetching: bool,
  onShippingPromiseUpdate: func.isRequired,
  remaining: Remaining,
  key: string,
  subtitles: arrayOf(
    shape({
      text: string,
      color: string,
      values: shape({
        price: shape({
          type: string,
          value: number,
          original_value: number,
          currency_symbol: string,
        }),
      }),
    }),
  ),
  title: shape({
    text: string.isRequired,
    color: string,
  }).isRequired,
  showChevron: bool,
  tooltip: shape({
    side: string,
    content: node,
  }),
  num_font_size: number,
};

PickUp.defaultProps = {
  action: null,
  icon: null,
  key: null,
  isFetching: false,
  subtitles: null,
  className: null,
  remaining: null,
  showChevron: false,
  tooltip: null,
};

export default setTooltipProps(PickUp);
