import React, { useContext } from 'react';
import { bool, func, number, string, shape } from 'prop-types';
import classnames from 'classnames';
import { Action, Label } from '../../../prop-types';
import colornames from '../../../lib/colornames';
import { stringIncludes } from '../../../lib/includes';
import PickUp from '../../pick-up';
import ActionModal from '../../action-modal';
import Iframe from '../../iframe';
import StaticPropsContext from '../../context/static-props';
import onIframeModalResize from '../../../lib/on-iframe-modal-resize';
import { PriceFontSize } from '../../../lib/num-font-size-enum';
import TooltipWrapper from '../../tooltip/tooltip-wrapper';
import useTooltipProps from '../../tooltip/hooks/useTooltipProps';
import IconFactory from '../../icons/factory-builder';

const namespace = 'ui-pdp-pick-up';

const getAction = (
  deviceType,
  { modal_title, close_modal_label, icon, target, label, html_target: htmlTarget },
  onIframeMessage,
) => {
  const isNewVersionModal = stringIncludes(target, 'new_version=true');
  if ((isNewVersionModal || deviceType === 'desktop') && !htmlTarget) {
    return (
      <ActionModal
        icon={icon ? IconFactory(icon, `${namespace}__action-icon`) : null}
        label={label}
        modalUrl="#pick-up"
        url={target}
        modalClassName="ui-pdp-iframe-modal"
        modalTitle={modal_title}
        autoHeight={false}
        closeModalLabel={close_modal_label}
      >
        <Iframe src={target} title={label.text} onMessage={onIframeMessage} scrolling="no" />
      </ActionModal>
    );
  }

  return (
    <div className="ui-pdp-action-modal">
      <a target={htmlTarget || '_self'} href={target}>
        {icon && (
          <div aria-hidden="true" className={classnames(`${namespace}__action-icon`, 'ui-pdp-action-modal__icon')}>
            {IconFactory(icon, `${namespace}__action-icon`)}
          </div>
        )}
        {label.text}
      </a>
    </div>
  );
};

const GAP = -16;

const PickUpSummary = ({
  action,
  className,
  isFetching,
  title,
  onShippingPromiseUpdate,
  tooltip,
  information_tooltip,
  closeButtonLabel,
  num_font_size,
  ...rest
}) => {
  const { deviceType } = useContext(StaticPropsContext);
  const tooltipProps = useTooltipProps(information_tooltip);

  if (!title) {
    return null;
  }

  return (
    <TooltipWrapper
      className="ui-vpp-pickup"
      tooltip={tooltipProps ? { ...tooltipProps, offsetX: GAP, offsetY: undefined } : null}
    >
      <PickUp
        isFetching={isFetching}
        num_font_size={num_font_size}
        action={
          action
            ? getAction(
                deviceType,
                action,
                onIframeModalResize(
                  'vip:modal-content:loaded',
                  'height',
                  '.ui-pdp-iframe-modal',
                  '.andes-modal__header',
                ),
                closeButtonLabel,
              )
            : null
        }
        className={classnames('ui-pdp-pick-up--md', className, colornames(title.color))}
        title={title}
        onShippingPromiseUpdate={onShippingPromiseUpdate}
        {...rest}
        showChevron={!!tooltip && tooltip.behaviour !== 'FIXED'}
        tooltip={tooltip}
      />
    </TooltipWrapper>
  );
};

PickUpSummary.propTypes = {
  action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    target: string.isRequired,
    htmlTarget: string,
  }),
  className: string,
  icon: shape({
    id: string.isRequired,
    color: string,
  }),
  isFetching: bool,
  text: string,
  title: shape({
    text: string.isRequired,
    color: string,
    values: shape(),
  }).isRequired,
  tooltip: shape({}),
  onShippingPromiseUpdate: func,
  information_tooltip: shape({
    label: Label,
    primary_button: shape({
      Label,
      action_type: string,
    }),
    primary_link: shape({ Action }),
    time_frequency: number,
  }),
  closeButtonLabel: string,
  num_font_size: number,
};

PickUpSummary.defaultProps = {
  action: null,
  icon: null,
  isFetching: false,
  className: null,
  text: null,
  information_tooltip: null,
  tooltip: null,
  onShippingPromiseUpdate: null,
  num_font_size: PriceFontSize.MEDIA_SUBTITLE,
};

export default PickUpSummary;
