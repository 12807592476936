import React, { useContext } from 'react';
import { arrayOf, shape, string, func, number } from 'prop-types';
import classNames from 'classnames';
import { Action, Label } from '../../prop-types';
import OtherSellersItem from './other-sellers-item';
import componentEnhance from '../../lib/component-enhance';
import { trackEvent } from '../../lib/tracking';
import StaticPropsContext from '../context/static-props';
import { PriceFontSize } from '../../lib/num-font-size-enum';

const namespace = 'ui-pdp-other-sellers';

const OtherSellers = ({
  action: { label, target, track },
  className,
  items,
  parentUrl,
  selectedQuantity,
  title,
  showAddToCartModal,
}) => {
  const { lowEnd, deviceType } = useContext(StaticPropsContext);
  const collapsable = deviceType === 'mobile' && !lowEnd;
  return (
    <div className={classNames(namespace, className)}>
      <h2 className={`${namespace}__title`} data-testid="title">
        {title.text}
      </h2>
      <ul data-testid="items">
        {items.map(item => (
          <OtherSellersItem
            key={`other-seller-item-${item.id}`}
            {...item}
            collapsable={collapsable}
            parentUrl={parentUrl}
            selectedQuantity={selectedQuantity}
            showAddToCartModal={showAddToCartModal}
          />
        ))}
      </ul>
      <a
        href={target}
        onClick={() => {
          trackEvent(track);
        }}
        className={`${namespace}__link`}
        data-testid="link"
      >
        {componentEnhance.jsx(label.text, label.values, null, PriceFontSize.OTHER_SELLERS.LINK)}
      </a>
    </div>
  );
};

OtherSellers.propTypes = {
  action: Action.isRequired,
  className: string,
  items: arrayOf(shape(OtherSellersItem.propsTypes)).isRequired,
  parentUrl: string.isRequired,
  selectedQuantity: number.isRequired,
  title: Label.isRequired,
  showAddToCartModal: func,
};

OtherSellers.defaultProps = {
  className: null,
  showAddToCartModal: null,
};

export default OtherSellers;
