import React, { useRef, useEffect, useState } from 'react';
import { func, bool, string, shape, node, number } from 'prop-types';
import classNames from 'classnames';
import Actions from '../buybox/actions';
import BuyBox from '../buybox';
import SummaryList from '../summary-list';
import Price from '../price';
import withMappedProps from '../../utils/withMappedProps';
import SellerInfoMapJsonToProps from '../seller-info-label/json-to-props';
import SellerInfoComponent from '../seller-info-label/seller-info.desktop';
import { Label } from '../../prop-types';
import IconChevron from '../icons/chevron';
import { PriceFontSize, DiscountFontSize } from '../../lib/num-font-size-enum';
import renderComponent from '../../pages/s/components/results/render-component/render-component';

const SellerInfo = withMappedProps(SellerInfoComponent)(SellerInfoMapJsonToProps);

const namespace = 'ui-pdp-other-sellers-item';

const Header = ({ collapsable, collapsed, onClick, children, id }) =>
  collapsable ? (
    <button
      className={classNames(`${namespace}__list-top`, {
        [`${namespace}__list-top-interactive`]: collapsable,
      })}
      aria-expanded={collapsed ? 'false' : 'true'}
      aria-controls={`other-sellers-item-${id}`}
      onClick={onClick}
      data-testid="main-button"
      type="button"
    >
      {children}
      <IconChevron className={classNames(`${namespace}__chevron ui-pdp-icon--chevron-down`)} />
    </button>
  ) : (
    <div className={`${namespace}__list-top`}>{children}</div>
  );
const OtherSellersItem = ({
  id,
  collapsable,
  collapsed,
  parentUrl,
  selectedQuantity,
  price,
  product_tag,
  summary_list,
  seller,
  main_actions,
  cbt_summary,
  showAddToCartModal,
}) => {
  const ref = useRef();
  const [collapsedState, setCollapsedState] = useState({ ready: false, collapsed, expandedHeight: 0 });
  const {
    price: { original_value: originalValue, currency_symbol: currencySymbol, value },
    discount_label: discountLabel,
    subtitles,
    price_tags,
    discounts_tags,
  } = price;
  const onClickInternal = () => {
    setCollapsedState({
      ...collapsedState,
      collapsed: !collapsedState.collapsed,
    });
  };
  useEffect(() => {
    if (collapsable && !collapsedState.ready) {
      setCollapsedState({ ...collapsedState, ready: true, expandedHeight: ref?.current?.offsetHeight });
    }
  }, [collapsedState.expandedHeight, collapsedState.ready, ref, collapsable, collapsedState]);
  const currentHeight = collapsedState.collapsed ? 0 : collapsedState.expandedHeight;
  return (
    <li
      className={classNames(namespace, {
        [`${namespace}--is-collapsed`]: collapsable && collapsedState.collapsed,
        [`${namespace}--is-not-ready`]: collapsable && !collapsedState.ready,
      })}
      id={`other-sellers-item-${id}`}
    >
      <Header onClick={onClickInternal} collapsable={collapsable} collapsed={collapsedState.collapsed} id={id}>
        <div className={`${namespace}__price`}>
          <Price
            {...{
              value,
              originalValue,
              currencySymbol,
              discountLabel,
              subtitles,
              price_tags,
              discounts_tags,
              num_font_size: PriceFontSize.OTHER_SELLERS.ITEM,
              original_value_num_font_size: PriceFontSize.OTHER_SELLERS.ORIGINAL,
              discount_num_font_size: DiscountFontSize.OTHER_SELLERS.ITEM,
            }}
          />
        </div>
        <span className={`${namespace}__tag`}>{product_tag.text}</span>
      </Header>
      {(!collapsable || (collapsable && !collapsedState.collapsed)) && (
        <ul
          className={`${namespace}__list`}
          ref={ref}
          style={collapsable && collapsedState.ready ? { height: `${currentHeight}px` } : {}}
        >
          <li>
            <SummaryList {...summary_list} />
          </li>
          <li>
            {renderComponent({
              type: 'cbt_summary',
              components: [cbt_summary],
              className: `${namespace}__cbt`,
            })}
          </li>
          <li>
            <SellerInfo className={`${namespace}__seller`} {...seller} />
          </li>
          <li className={`${namespace}__actions`}>
            <BuyBox className={`${namespace}__buybox`}>
              <Actions
                size="medium"
                {...main_actions}
                form={{ ...main_actions.form, parent_url: parentUrl, quantity: selectedQuantity }}
                showAddToCartModal={showAddToCartModal}
              />
            </BuyBox>
          </li>
        </ul>
      )}
    </li>
  );
};

Header.propTypes = {
  id: string.isRequired,
  children: node.isRequired,
  collapsable: bool,
  collapsed: bool,
  onClick: func,
};

OtherSellersItem.propTypes = {
  id: string.isRequired,
  collapsable: bool,
  collapsed: bool,
  main_actions: shape(Actions.propTypes).isRequired,
  parentUrl: string.isRequired,
  selectedQuantity: number.isRequired,
  product_tag: Label.isRequired,
  price: shape({}).isRequired,
  summary_list: shape(SummaryList.propTypes).isRequired,
  cbt_summary: shape({}),
  seller: shape({}).isRequired,
  onClick: func,
  showAddToCartModal: func,
};

OtherSellersItem.defaultProps = {
  collapsable: true,
  collapsed: true,
  onClick: null,
  showAddToCartModal: null,
};

export default OtherSellersItem;
